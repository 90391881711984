import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import api from "../../services/api";
import logoNoBg from "../../assets/logoLion-removebg-preview.png";

export default function Login() {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  async function login(e){
    e.preventDefault()

    const data = {
      email: userName,
      password: password
    };

    try {
      const response = await api.post('api/v1/Login', data);

      localStorage.setItem('userName', userName);
      localStorage.setItem('token', response.data.token);

      navigate('/Home');
    } catch (error) {
      alert('Login falhou!');
    }
  }

  return (
    <div className="login-container">
      <img src={logoNoBg} alt="Lion Logo" />
      <form>
        <h1>Acesse sua conta</h1>
        <input placeholder="Usuário" value={userName} onChange={e => setUserName(e.target.value)} />
        <input type="password" placeholder="Senha" value={password} onChange={e => setPassword(e.target.value)} />

        <button type="submit" className="button" onClick={login}>
          Entrar
        </button>
      </form>
    </div>
  );
}
