import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import api from "../../services/api";
import "./styles.css";
import logoNoBg from "../../assets/logoLion-removebg-preview.png";

export default function ProductForm() {
  const [productName, setProductName] = useState('');
  const [balance, setBalance] = useState('');
  const [minimumStock, setMinimumStock] = useState('');
  const [certificationExpiryDate, setCertificationExpiryDate] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [certificationNumber, setCertificationNumber] = useState('');

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const fetchProduct = async () => {
        try {
          const response = await api.get(`api/v1/product/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });

          const product = response.data;
          setProductName(product.productName);
          setBalance(product.balance);
          setMinimumStock(product.minimumStock);
          setCertificationExpiryDate(product.approvalCertification.certificationExpiryDate);
          setManufacturer(product.approvalCertification.manufacturer);
          setCertificationNumber(product.approvalCertification.certificationNumber);
        } catch (error) {
          console.error("Erro ao carregar produto", error);
        }
      };

      fetchProduct();
    }
  }, [id]);

  async function handleSubmit(e) {
    e.preventDefault();

    const productData = {
      id: id ? id : undefined,
      productName,
      balance: parseInt(balance),
      minimumStock: parseInt(minimumStock),
      approvalCertification: {
        manufacturer,
        certificationNumber,
        certificationExpiryDate
      },
    };

    const accessToken = localStorage.getItem('token');

    try {
      if (id) {
        await api.put(`api/v1/product/${id}`, productData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        alert("Produto atualizado com sucesso!");
      } else {
        await api.post('api/v1/product', productData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        alert("Produto adicionado com sucesso!");
      }

      navigate("/products");
    } catch (error) {
      alert("Erro ao salvar produto, contate o suporte!");
    }
  }

  return (
    <div className="new-product-container">
      <div className="content">
        <section className="form">
          <img src={logoNoBg} alt="Logo Lion" />
          <h1>{id ? 'Editar produto' : 'Adicionar novo produto'}</h1>
          <p>{id ? 'Edite as informações do produto.' : 'Adicione as informações do produto.'}</p>
          <Link className="back-link" to="/products">
            <FiArrowLeft size={16} color="#e37636" />
            Voltar para os Produtos
          </Link>
        </section>
        <form onSubmit={handleSubmit}>
          <label>Nome do Produto</label>
          <input
            id="name"
            placeholder="Nome do Produto"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            required
          />

          <label htmlFor="balance">Saldo</label>
          <input
            id="balance"
            placeholder="Saldo"
            type="number"
            value={balance}
            onChange={(e) => setBalance(e.target.value)}
            required
          />

          <label htmlFor="minimumStock">Estoque Mínimo</label>
          <input
            id="minimumStock"
            placeholder="Estoque Mínimo"
            type="number"
            value={minimumStock}
            onChange={(e) => setMinimumStock(e.target.value)}
            required
          />

          <label htmlFor="codigo">Vencimento C.A.</label>
          <input
            id="certificationExpiryDate"
            placeholder="Data de vencimento CA"
            type="date"
            value={certificationExpiryDate}
            onChange={(e) => setCertificationExpiryDate(e.target.value)}
          />

          <label htmlFor="codigo">Código C.A.</label>
          <input
            id="certificationNumber"
            placeholder="Codigo C.A"
            type="number"
            value={certificationNumber}
            onChange={(e) => setCertificationNumber(e.target.value)}
          />

          <label htmlFor="codigo">Fabricante</label>
          <input
            id="manufacturer"
            placeholder="Fabricante"
            type="text"
            value={manufacturer}
            onChange={(e) => setManufacturer(e.target.value)}
          />

          <button className="button" type="submit">
            {id ? 'Salvar' : 'Adicionar'}
          </button>
        </form>
      </div>
    </div>
  );
}
