import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiHome, FiEdit, FiTrash } from "react-icons/fi";
import api from "../../services/api";
import "./styles.css";
import logoNoBg from "../../assets/logoLion-removebg-preview.png";

export default function Collaborators() {
  const [collaborators, setCollaborators] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOption, setFilterOption] = useState("all");
  const userName = localStorage.getItem("userName");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCollaborators = async () => {
      try {
        let response;
        if (filterOption === "expiringSoon") {
          response = await api.get("api/v1/collaborator/expiring-soon", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
        } else if (filterOption === "expired") {
          response = await api.get("api/v1/collaborator/expired", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
        } else {
          response = await api.get("api/v1/collaborator", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
        }
        setCollaborators(response.data);
      } catch (error) {
        console.error("Erro ao buscar colaboradores:", error);
      }
    };

    fetchCollaborators();
  }, [filterOption]);

  const handleDelete = async (id) => {
    try {
      await api.delete(`api/v1/collaborator/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setCollaborators(collaborators.filter((col) => col.id !== id));
    } catch (error) {
      console.error("Erro ao deletar colaborador:", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/collaborators/edit/${id}`);
  };

  const filteredCollaborators = collaborators.filter((collaborator) =>
    collaborator.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="collaborator-container">
      <header>
        <img src={logoNoBg} alt="Lion Logo" />
        <span>
          Bem-vindo(a), <strong>{userName}</strong>!
        </span>
        <div className="header-buttons-container">
          <Link className="button" to="/collaborators/new">
            Novo Colaborador
          </Link>
          <Link className="button-home" aria-label="Home" to="/Home">
            <FiHome size={18} color="#e37636" />
          </Link>
        </div>
      </header>

      <div className="collaborator-header">
        <h1>Colaboradores</h1>
        <input
          type="text"
          placeholder="Buscar colaborador por nome"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
          aria-label="Buscar colaborador por nome"
        />

        <select
          className="filter-select"
          value={filterOption}
          onChange={(e) => setFilterOption(e.target.value)}
          aria-label="Filtro de colaboradores"
        >
          <option value="all">Todos</option>
          <option value="expiringSoon">Próximos de Vencer</option>
          <option value="expired">Vencidos</option>
        </select>
      </div>

      <table className="collaborator-table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>ASO</th>
            <th>Avaliação Psicológica/Psicossocial</th>
            <th>NR10</th>
            <th>NR35</th>
            <th>Direção Defensiva</th>
            <th>HAR</th>
            <th>CNH</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {filteredCollaborators.map((collaborator) => (
            <tr key={collaborator.id}>
              <td>{collaborator.name}</td>
              <td className={collaborator.exams.find(e=>e.examName === "ASO").isExpiringSoon ? "expiring" : ""}>{collaborator.exams.find(e => e.examName === "ASO")?.expiryDate && new Date(collaborator.exams.find(e => e.examName === "ASO").expiryDate).toLocaleDateString()}</td>
              <td className={collaborator.exams.find(e=>e.examName === "Avaliaçao Psicologica")?.isExpiringSoon ? "expiring" : ""}>{collaborator.exams.find(e => e.examName === "Avaliaçao Psicologica")?.expiryDate && new Date(collaborator.exams.find(e => e.examName === "Avaliaçao Psicologica").expiryDate).toLocaleDateString() || "NA"}</td>
              <td className={collaborator.exams.find(e=>e.examName === "NR10")?.isExpiringSoon ? "expiring" : ""}>{collaborator.exams.find(e => e.examName === "NR10")?.expiryDate && new Date(collaborator.exams.find(e => e.examName === "NR10").expiryDate).toLocaleDateString() || "NA"}</td>
              <td className={collaborator.exams.find(e=>e.examName === "NR35")?.isExpiringSoon ? "expiring" : ""}>{collaborator.exams.find(e => e.examName === "NR35")?.expiryDate && new Date(collaborator.exams.find(e => e.examName === "NR35").expiryDate).toLocaleDateString() || "NA"}</td>
              <td className={collaborator.exams.find(e=>e.examName === "Direcao Defensiva")?.isExpiringSoon ? "expiring" : ""}>{collaborator.exams.find(e => e.examName === "Direcao Defensiva")?.expiryDate && new Date(collaborator.exams.find(e => e.examName === "Direcao Defensiva").expiryDate).toLocaleDateString() || "NA"}</td>
              <td className={collaborator.exams.find(e => e.examName === "HAR")?.isExpiringSoon ? "expiring" : ""}>{collaborator.exams.find(e => e.examName === "HAR")?.expiryDate && new Date(collaborator.exams.find(e => e.examName === "HAR").expiryDate).toLocaleDateString() || "NA"}</td>
              <td>{collaborator.cnhExpiryDate && new Date(collaborator.cnhExpiryDate).toLocaleDateString() || "NA"}</td>
              <td>
                <button
                  type="button"
                  onClick={() => handleEdit(collaborator.id)}
                  aria-label={`Editar colaborador ${collaborator.name}`}
                >
                  <FiEdit size={20} color="#e37636" />
                </button>
                <button
                  type="button"
                  onClick={() => handleDelete(collaborator.id)}
                  aria-label={`Deletar colaborador ${collaborator.name}`}
                >
                  <FiTrash size={20} color="#e37636" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
