import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./pages/login";
import Collaborators from "./pages/collaborators";
import NewCollaborator from "./pages/newCollaborator";
import NewProduct from "./pages/newProduct";
import Home from "./pages/home";
import Products from "./pages/Products";

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Collaborators" element={<Collaborators />} />
        <Route path="/Collaborators/New" element={<NewCollaborator />} />
        <Route path="/Collaborators/Edit/:id" element={<NewCollaborator />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/Products/New" element={<NewProduct />} />
        <Route path="/Products/Edit/:id" element={<NewProduct />} />
      </Routes>
    </BrowserRouter>
  );
}
