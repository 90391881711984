import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { FiLogOut } from "react-icons/fi";

export default function Home() {
  return (
    <div className="home-container">
      <header>
        <img src={require("../../assets/logoLion-removebg-preview.png")} alt="Logo" />
        <h1>Bem-vindo(a) à sua plataforma de gerenciamento!</h1>
        <p>Escolha uma das opções para continuar:</p>
      </header>

      <div className="options-container">
        <Link className="button" to="/collaborators">
          Ver Colaboradores
        </Link>

        <Link className="button" to="/products">
          Ver Produtos e Estoque
        </Link>
        <Link className="back-link" to="/">
            <FiLogOut size={20} color="#e37636" />
          </Link>
      </div>
    </div>
  );
}
