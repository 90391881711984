import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiEdit, FiHome, FiTrash } from "react-icons/fi";
import api from "../../services/api";
import "./styles.css";
import logoNoBg from "../../assets/logoLion-removebg-preview.png";

export default function Products() {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const userName = localStorage.getItem("userName");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await api.get("api/v1/product", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setProducts(response.data);
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      }
    };

    fetchProducts();
  }, []);

  const handleDelete = async (id) => {
    try {
      await api.delete(`api/v1/product/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setProducts(products.filter((product) => product.id !== id));
    } catch (error) {
      console.error("Erro ao deletar produto:", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/products/edit/${id}`);
  };

  const filteredProducts = products.filter((product) => {
    const productName = product.productName || '';
    return productName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <div className="product-container">
      <header>
        <img src={logoNoBg} alt="Logo" />
        <span>
          Bem-vindo(a), <strong>{userName}</strong>!
        </span>
        <div className="header-buttons-container">
          <Link className="button" to="/products/new">
            Novo produto
          </Link>
          <Link className="button-home" aria-label="Home" to="/Home">
            <FiHome size={18} color="#e37636" />
          </Link>
        </div>
      </header>

      <div className="product-header">
        <h1>Produtos</h1>
        <input
          type="text"
          placeholder="Buscar produto por nome"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
          aria-label="Buscar produto por nome"
        />
      </div>

      <table className="product-table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Saldo</th>
            <th>Estoque Mínimo</th>
            <th>Status</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map((product) => (
            <React.Fragment key={product.id}>
              <tr>
                <td>{product.productName}</td>
                <td>{product.balance}</td>
                <td>{product.minimumStock}</td>
                <td>{product.status}</td>
                <td>
                  <button
                    type="button"
                    onClick={() => handleEdit(product.id)}
                    aria-label={`Editar produto ${product.productName}`}
                  >
                    <FiEdit size={20} color="#e37636" />
                  </button>
                  <button
                    type="button"
                    onClick={() => handleDelete(product.id)}
                    aria-label={`Deletar produto ${product.productName}`}
                  >
                    <FiTrash size={20} color="#e37636" />
                  </button>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
