import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import api from "../../services/api";
import "./styles.css";
import logoNoBg from "../../assets/logoLion-removebg-preview.png";

export default function CollaboratorForm() {
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [asoDate, setAsoDate] = useState('');
  const [nr10Date, setNr10Date] = useState('');
  const [nr35Date, setNr35Date] = useState('');
  const [harDate, setHarDate] = useState('');
  const [defensiveDrivingDate, setDefensiveDrivingDate] = useState('');
  const [cnhExpiryDate, setCnhExpiryDate] = useState('');

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const fetchCollaborator = async () => {
        try {
          const response = await api.get(`api/v1/collaborator/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });

          const collaborator = response.data;
          setName(collaborator.name);
          setCpf(collaborator.cpf);
          setPhone(collaborator.phone);
          
          const asoExam = collaborator.exams.find(exam => exam.examName === 'ASO');
          if (asoExam) setAsoDate(asoExam.examDate);

          const nr10Exam = collaborator.exams.find(exam => exam.examName === 'NR10');
          if (nr10Exam) setNr10Date(nr10Exam.examDate);

          const nr35Exam = collaborator.exams.find(exam => exam.examName === 'NR35');
          if (nr35Exam) setNr35Date(nr35Exam.examDate);

          const harExam = collaborator.exams.find(exam => exam.examName === 'HAR');
          if (harExam) setHarDate(harExam.examDate);

          const defensiveDrivingExam = collaborator.exams.find(exam => exam.examName === 'direcao defensiva');
          if (defensiveDrivingExam) setDefensiveDrivingDate(defensiveDrivingExam.examDate);

          setCnhExpiryDate(collaborator.cnhExpiryDate);

        } catch (error) {
          console.error("Erro ao carregar colaborador", error);
        }
      };

      fetchCollaborator();
    }
  }, [id]);

  async function handleSubmit(e) {
    e.preventDefault();

    const collaboratorData = {
      name,
      cpf,
      phone,
      exams: [
        {
          examName: 'ASO',
          examDate: asoDate,
        },
        {
          examName: 'NR10',
          examDate: nr10Date,
        },
        {
          examName: 'NR35',
          examDate: nr35Date,
        },
        {
          examName: 'HAR',
          examDate: harDate,
        },
        {
          examName: 'Direcao Defensiva',
          examDate: defensiveDrivingDate,
        }
      ],
      cnhExpiryDate,
    };

    const accessToken = localStorage.getItem('token');

    try {
      if (id) {
        await api.put(`api/v1/collaborator/${id}`, collaboratorData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        alert("Colaborador atualizado com sucesso!");
      } else {
        await api.post('api/v1/collaborator', collaboratorData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        alert("Colaborador adicionado com sucesso!");
      }

      navigate("/collaborators");
    } catch (error) {
      alert("Erro ao salvar colaborador, contate o suporte!");
    }
  }

  return (
    <div className="new-collaborator-container">
      <div className="content">
        <section className="form">
          <img src={logoNoBg} alt="Logo Lion" />
          <h1>{id ? 'Editar colaborador' : 'Adicionar novo colaborador'}</h1>
          <p>{id ? 'Edite as informações do colaborador.' : 'Adicione as informações do colaborador.'}</p>
          <Link className="back-link" to="/collaborators">
            <FiArrowLeft size={16} color="#e37636" />
            Home
          </Link>
        </section>
        <form onSubmit={handleSubmit}>
          <label>Nome</label>
          <input
            id="name"
            placeholder="Nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <label htmlFor="cpf">CPF</label>
          <input
            id="cpf"
            placeholder="CPF"
            type="text"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
          />

          <label htmlFor="phone">Telefone</label>
          <input
            id="phone"
            placeholder="Telefone"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          <label htmlFor="aso">ASO</label>
          <input
            id="aso"
            placeholder="ASO"
            type="date"
            value={asoDate}
            onChange={(e) => setAsoDate(e.target.value)}
          />

          <label htmlFor="nr10">NR10</label>
          <input
            id="nr10"
            placeholder="NR10"
            type="date"
            value={nr10Date}
            onChange={(e) => setNr10Date(e.target.value)}
          />

          <label htmlFor="nr35">NR35</label>
          <input
            id="nr35"
            placeholder="NR35"
            type="date"
            value={nr35Date}
            onChange={(e) => setNr35Date(e.target.value)}
          />

          <label htmlFor="har">HAR</label>
          <input
            id="har"
            placeholder="HAR"
            type="date"
            value={harDate}
            onChange={(e) => setHarDate(e.target.value)}
          />

          <label htmlFor="defensiveDriving">Direção Defensiva</label>
          <input
            id="defensiveDriving"
            placeholder="Direção Defensiva"
            type="date"
            value={defensiveDrivingDate}
            onChange={(e) => setDefensiveDrivingDate(e.target.value)}
          />

          <label htmlFor="cnhExpiry">Data de vencimento da CNH</label>
          <input
            id="cnhExpiry"
            placeholder="Data de vencimento da CNH"
            type="date"
            value={cnhExpiryDate}
            onChange={(e) => setCnhExpiryDate(e.target.value)}
          />

          <button className="button" type="submit">
            {id ? 'Salvar' : 'Adicionar'}
          </button>
        </form>
      </div>
    </div>
  );
}
